import React from 'react';
import styled from 'styled-components';
import track1 from '../../assets/images/track1.png';
import track2 from '../../assets/images/track2.png';
import mainTrackBackImage1 from '../../assets/images/mainTrackBackImage1.svg';
import mainTrackBackImage2 from '../../assets/images/mainTrackBackImage2.svg';
import { Link } from 'react-router-dom';

export default function MainTrack() {
  return (
    <TrackWrapper>
      <TrackCardWrapper>
        <BackImage1 src={mainTrackBackImage1} />
        <BackImage2 src={mainTrackBackImage2} />
        <Card>
          <Image src={track1} alt="track1" />
          <CardTextWrapper>
            <TitleSide>
              <KMove>K-Move 트랙 Ⅰ</KMove>
              <CardText>
                2024년 일본취업 산업융합
                <br />
                소프트웨어 전문가 양성과정
              </CardText>
              <TrackDateWrap>
                <TrackDate>접수 마감</TrackDate>
              </TrackDateWrap>
            </TitleSide>
          </CardTextWrapper>
          {/* <Link to="/track1"> */}
          <Detail>상세보기</Detail>
          {/* </Link> */}
        </Card>
        <Card>
          <Image src={track2} alt="trakc2" />
          <CardTextWrapper>
            <TitleSide>
              <KMove>K-Move 트랙 Ⅱ</KMove>
              <CardText>
                2024년 일본취업 클라우드 기반
                <br />
                풀스택 웹 개발자 양성과정
              </CardText>
              <TrackDateWrap2>
                <TrackDate>
                  6월 27일 개강 <br />
                  모집중
                </TrackDate>
              </TrackDateWrap2>
            </TitleSide>
            {/* <Explain>
              <TrackText1>
                IT직무<br/>
                일본어<br/>
                국비교육
              </TrackText1>
              <TrackText2>
                이력서 컨설팅<br/>
                면접 스피치<br/>
                지원
              </TrackText2>
              <TrackText3>
                기업설명회<br/>
                면접 등<br/>
                취업지원
              </TrackText3>
              <TrackText4>
                해외정착금<br/>
                500만원<br/>
                지원
              </TrackText4>
            </Explain> */}
          </CardTextWrapper>
          <Link to="/track2">
            <Detail>상세보기</Detail>
          </Link>
        </Card>
      </TrackCardWrapper>
    </TrackWrapper>
  );
}

const TrackWrapper = styled.div`
  width: 100%;
`;

const BackImage1 = styled.img`
  position: absolute;
  top: 80px;
  left: 0;
`;

const BackImage2 = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;

const CardTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 22px 0px 22px 48px;
  position: absolute;
`;

const TitleSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 350px;
`;

const Explain = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Detail = styled.div`
  width: 50%;
  padding: 0.3rem 0;
  font-size: 1.375rem;
  font-weight: 600;
  background: #bad8e6;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
`;

const TrackCardWrapper = styled.div`
  width: 100%;
  height: 504px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;
`;

const Card = styled.div`
  width: 588px;
  height: 382px;
  position: relative;
`;

const Image = styled.img`
  width: 588px;
  height: 382px;
  object-fit: cover;
  position: absolute;
`;

const KMove = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 2rem;
  font-family: Pretendard, sans-serif;
  font-weight: 700;
  text-align: left;
`;

const CardText = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.5rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const TrackDate = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 18px;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: center;
  white-space: nowrap;
`;

const TrackText1 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  text-align: left;
  width: 109px;
`;

const TrackText2 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  text-align: left;
  width: 130px;
`;

const TrackText3 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  text-align: left;
  width: 109px;
`;

const TrackText4 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  text-align: left;
  width: 109px;
`;

const TrackDateWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex: none;
  gap: 10px;
  border-radius: 9999px;
  background-color: rgba(97, 97, 97, 0.8);
  box-sizing: border-box;
  padding: 2px 20px;
  position: absolute;
  top: 20px;
  left: 90%;
  width: 200px;
  height: 58px;
`;

const TrackDateWrap2 = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex: none;
  gap: 10px;
  border-radius: 9999px;
  background-color: rgba(97, 97, 97, 0.8);
  box-sizing: border-box;
  padding: 2px 20px;
  position: absolute;
  top: 20px;
  left: 90%;
  width: 200px;
`;

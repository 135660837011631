import React, { useRef, useState } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { styled } from 'styled-components';
import slide1 from '../assets/images/slide1.png';
import slide2 from '../assets/images/slide2.png';
import slide3 from '../assets/images/slide3.svg';
import mainSlideButton1 from '../assets/images/mainSlideButton1.svg';
import mainSlideButton2 from '../assets/images/mainSlideButton2.svg';
import mainSlideButton3 from '../assets/images/mainSlideButton3.svg';
import { Link } from "react-router-dom";

export default function MainSliderMobile() {
	const slickRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

	const kakao = () => {
		if (window.Kakao) {
			const kakao = window.Kakao;
			if (!kakao.isInitialized()) {
				kakao.init('cc070c6c13d3847a59320562ae6d84bb');
			}
		}
		
		window.Kakao.Channel.chat({
			channelPublicId: '_xnYuNG'
		});
	}

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		beforeChange: (current, next) => {
      setActiveSlide(next);
    },
	};
	return (
		<Wrapper>
			<Slider {...settings} ref={slickRef}>
				<div>
					<SlideWrap>
						<SlideImg src={slide1} />
						<SlideText>
							<BigText>
								K-Move 해외취업 국비 무료 교육
							</BigText>
							<SmallText>
								수준 높은 IT기술과 최고의 강사들을 통한<br/>
								일본취업 전문 연수기관<br/>
								최고의 전문가들의 수준 높은<br/>
								IT기술이 반영된 커리큘럼<br/>
							</SmallText>
							<span>연수생 모집중</span>
							<ButtonWrapper>
								<Link to="/track2">
									<MainSliderButton src={mainSlideButton1}/>
								</Link>
								<MainSliderButton src={mainSlideButton2} style={{ width: '6.5rem'}} onClick={kakao}/>
								<MainSliderButton src={mainSlideButton3} onClick={() => document.location.href='tel:0269588204'}/>
							</ButtonWrapper>
						</SlideText>
					</SlideWrap>
				</div>
				<div>
					<SlideWrap>
						<SlideImg src={slide2} />
						<SlideText>
							<BigText>
								K-Move 해외취업 국비 무료 교육
							</BigText>
							<SmallText>
								2024년 일본취업 클라우드 기반<br/>
								풀 스택 웹개발자 양성과정<br/>
							</SmallText>
							<span>연수생 모집중</span>
							<ButtonWrapper>
								<Link to="/track2">
									<MainSliderButton src={mainSlideButton1}/>
								</Link>
								<MainSliderButton src={mainSlideButton2} style={{ width: '6.5rem'}} onClick={kakao}/>
								<MainSliderButton src={mainSlideButton3} onClick={() => document.location.href='tel:0269588204'}/>
							</ButtonWrapper>
						</SlideText>
					</SlideWrap>
				</div>
				<div>
					<SlideWrap>
						<SlideImg src={slide3} />
						<SlideText>
							<BigText>
								국내외 최신 트렌드에 맞춘<br/>
								IT 핵심기술을 반영
							</BigText>
							<SmallText>
								기업형 글로벌 아카데미 스페이스씨엘<br/>
								맞춤형 교육으로 글로벌 취업의 첫걸음!
							</SmallText>
							<ButtonWrapper>
								<Link to="/track2">
									<MainSliderButton src={mainSlideButton1}/>
								</Link>
								<MainSliderButton src={mainSlideButton2} style={{ width: '6.5rem'}} onClick={kakao}/>
								<MainSliderButton src={mainSlideButton3} onClick={() => document.location.href='tel:0269588204'}/>
							</ButtonWrapper>
						</SlideText>
					</SlideWrap>
				</div>
			</Slider>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	position: relative;
	width: 100%;
`;

const SlideWrap = styled.div`
	width: 100%;
	height: 671px;
	position: relative;
`;

const SlideText = styled.div`
	width: 370px;
	position: absolute;
	top: 8rem;
	left: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	> span {
		font-weight: 700;
		color: white;
	}
`;

const BigText = styled.div`
	font-size: 1.38rem;
	font-family: Pretendard, sans-serif;
	font-weight: 700;
	color: white;
	text-align: left;
`;

const SmallText = styled.div`
	font-size: 1rem;
	font-family: Pretendard, sans-serif;
	font-weight: 400;
	color: ${props => props.activeSlide === 0 ? 'rgb(34, 34, 34)' : 'white'};
	text-align: left;
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 0.5rem;
`;

const MainSliderButton = styled.img`
	width: 5rem;
`;

const SlideImg = styled.img`
	width: 100%;
	height: 677px;
	object-fit: cover;
`;
import React from "react";
import styled from "styled-components";
import backImage2 from "../../../assets/images/backImage2.png";
import backImage4 from "../../../assets/images/backImage4.png";
import backImage5_mobile from "../../../assets/images/backImage5_mobile.svg";
import backImage6_mobile from "../../../assets/images/backImage6_mobile.svg";
import supportImage1 from "../../../assets/images/supportImage1.png";
import supportImage2 from "../../../assets/images/supportImage2.png";
import supportImage3 from "../../../assets/images/supportImage3.png";
import supportImage4 from "../../../assets/images/supportImage4.png";

export default function SupportMatchingMobile() {
  return (
    <Wrapper>
      {/* <BackImage src={backImage1} right="0%" top="0px" /> */}
      <BackImage style={{ width: "110px", height: '180px'}} src={backImage2} left="0%" top="35%" />
      <BackImage src={backImage5_mobile} right="0%" top="85%" />
      <BackImage src={backImage4} left="75%" top="50%" />
      <BackImage src={backImage6_mobile} left="7%" top="97%" />
      <TitleWrapper>
        <Title>
          연수생-구인처 수요 매칭
        </Title>
      </TitleWrapper>
      <GroupWrapper>
        <Group1>
          <StepWrap>
            1 STEP
          </StepWrap>
          <CircleTitle>
            연수생 역량관리
          </CircleTitle>
          <CircleText>
            기업요구 역량 스킬업<br/>
            연수생 진로 취업상담<br/>
            기업면담, 구인요구분석
          </CircleText>
        </Group1>
        <Group2>
          <StepWrap>
            2 STEP
          </StepWrap>
          <CircleTitle>
            취업매칭 (면접)
          </CircleTitle>
          <CircleText>
            회사설명회,정보안내<br/>
            수요기업-연수생 개별매칭<br/>
            면접진행, 집중 스케줄링
          </CircleText>
        </Group2>
        <Group3>
          <StepWrap>
            3 STEP
          </StepWrap>
          <CircleTitle>
            결과관리
          </CircleTitle>
          <CircleText>
            취업자/미취업자 관리<br/>
            취업조건, 입직지원<br/>
            결과 피드백
          </CircleText>
        </Group3>
        <Group4>
          <StepWrap>
            4 STEP
          </StepWrap>
          <CircleTitle>
            성과관리
          </CircleTitle>
          <CircleText>
            취업자 업무적응 지원<br/>
            기업 네트워크 관리<br/>
            취업자 사후관리
          </CircleText>
        </Group4>
      </GroupWrapper>
    </Wrapper>
  )
}

const BackImage = styled.img`
  position: absolute;
  left: ${props => props.left};
  top: ${props => props.top};
  right: ${props => props.right};
 `; 

const Wrapper = styled.div`
  width: 100%;
  height: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  padding-top: 3rem;
  position: relative;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  `;

const GroupWrapper = styled.div`
  width: 240px;
  height: 888px;
  position: relative;
  z-index: 2;
`;

const Title = styled.span`
	color: rgb(34, 34, 34);
	font-size: 1.25rem;
	font-family: Pretendard, sans-serif;
	font-weight: 600;
	text-align: center;
`;

const Group1 = styled.div`
	width: 240px;
	height: 240px;
	position: absolute;
	left: 0px;
	top: 0px;
  z-index: 1;
  display: flex;
  background-image: url(${supportImage1}?${Date.now()});
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const CircleTitle = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 1.25rem;
	font-family: Pretendard, sans-serif;
	font-weight: 700;
	line-height: 150%;
	text-align: center;
`;

const CircleText = styled.span`
	color: white;
	text-overflow: ellipsis;
	font-size: 0.88rem;
	font-family: Pretendard, sans-serif;
	font-weight: 400;
	line-height: 150%;
	text-align: center;
`;

const StepWrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	width: 85px;
	height: 30px;
	background-color: white;
	box-sizing: border-box;
  color: rgb(38, 116, 255);
	font-size: 1rem;
	font-family: Pretendard, sans-serif;
	font-weight: 600;
	text-align: center;
`;

const Group2 = styled.div`
	width: 240px;
	height: 240px;
	position: absolute;
	top: 220px;
  z-index: 2;
  background-image: url(${supportImage2}?${Date.now()});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const Group3 = styled.div`
	width: 240px;
	height: 240px;
	position: absolute;
	top: 440px;
  z-index: 3;
  background-image: url(${supportImage3}?${Date.now()});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

const Group4 = styled.div`
	width: 240px;
	height: 240px;
	position: absolute;
	top: 660px;
  z-index: 4;
  background-image: url(${supportImage4}?${Date.now()});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
import React from 'react';
import styled from 'styled-components';
import track1 from '../../assets/images/track1.png';
import track2 from '../../assets/images/track2.png';
import { Link } from 'react-router-dom';

export default function MainTrackMobile() {
  return (
    <TrackWrapper>
      <TrackCardWrapper>
        <Card>
          <Image src={track1} alt="track1" />
          <CardTextWrapper>
            <TitleSide>
              <KMove>K-Move 트랙 Ⅰ</KMove>
              <CardText>
                2024년 일본취업 산업융합
                <br />
                소프트웨어 전문가 양성과정
              </CardText>
              <TrackDateWrap>
                <TrackDate>접수 마감</TrackDate>
              </TrackDateWrap>
            </TitleSide>
            {/* <Explain>
              <div>
                <TrackText1>
                  IT직무<br/>
                  일본어<br/>
                  국비교육 
                </TrackText1>
                <TrackText2>
                  이력서 컨설팅<br/>
                  면접 스피치<br/>
                  지원
                </TrackText2>
              </div>
              <div>
                <TrackText3>
                  기업설명회<br/>
                  면접 등<br/>
                  취업지원
                </TrackText3>
                <TrackText4>
                  해외정착금<br/>
                  500만원<br/>
                  지원
                </TrackText4>
              </div>
            </Explain> */}
          </CardTextWrapper>
          {/* <Link to="/track1"> */}
          <Detail>상세보기</Detail>
          {/* </Link> */}
        </Card>
        <Card>
          <Image src={track2} alt="trakc2" />
          <CardTextWrapper>
            <TitleSide>
              <KMove>K-Move 트랙 Ⅱ</KMove>
              <CardText>
                2024년 일본취업 클라우드 기반
                <br />
                풀스택 웹 개발자 양성과정
              </CardText>
              <TrackDateWrap>
                <TrackDate>6월 27일 개강</TrackDate>
              </TrackDateWrap>
            </TitleSide>
            {/* <Explain>
              <div>
                <TrackText1>
                  IT직무<br/>
                  일본어<br/>
                  국비교육
                </TrackText1>
                <TrackText2>
                  이력서 컨설팅<br/>
                  면접 스피치<br/>
                  지원
                </TrackText2>
              </div>
              <div>
                <TrackText3>
                  기업설명회<br/>
                  면접 등<br/>
                  취업지원
                </TrackText3>
                <TrackText4>
                  해외정착금<br/>
                  500만원<br/>
                  지원
                </TrackText4>
              </div>
            </Explain> */}
          </CardTextWrapper>
          <Link to="/track2">
            <Detail>상세보기</Detail>
          </Link>
        </Card>
      </TrackCardWrapper>
    </TrackWrapper>
  );
}

const Detail = styled.div`
  width: 75%;
  height: 31px;
  font-size: 1rem;
  font-weight: 600;
  background: #bad8e6;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
`;

const TrackWrapper = styled.div`
  width: 100%;
  margin-bottom: 5rem;
`;

const CardTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10rem;
  padding: 50px 10px 22px 38px;
  position: absolute;
`;

const TitleSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Explain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 1;

  > div {
    display: flex;
    gap: 3rem;
  }
`;

const TrackCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
`;

const Card = styled.div`
  width: 312px;
  height: 462px;
  position: relative;
`;

const Image = styled.img`
  width: 312px;
  height: 462px;
  object-fit: cover;
  position: absolute;
  border-radius: 10px;
`;

const KMove = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.5rem;
  font-family: Pretendard, sans-serif;
  font-weight: 700;
  text-align: left;
`;

const CardText = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 1.12rem;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const TrackDate = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 18px;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
  white-space: nowrap;
`;

const TrackText1 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const TrackText2 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const TrackText3 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const TrackText4 = styled.span`
  color: white;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  line-height: 150%;
  text-align: left;
`;

const TrackDateWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex: none;
  gap: 10px;
  border-radius: 9999px;
  background-color: rgba(97, 97, 97, 0.8);
  box-sizing: border-box;
  padding: 2px 15px;
`;

import React, { useRef, useCallback,useState } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { styled } from 'styled-components';
import slide1 from '../assets/images/slide1.png';
import slide2 from '../assets/images/slide2.png';
import slide3 from '../assets/images/slide3.svg';
import mainSlideButton1 from '../assets/images/mainSlideButton1.svg';
import mainSlideButton2 from '../assets/images/mainSlideButton2.svg';
import mainSlideButton3 from '../assets/images/mainSlideButton3.svg';
import slideLeftIcon from '../assets/images/slide_left_icon.svg';
import slideRightIcon from '../assets/images/slide_right_icon.svg';
import { Link } from "react-router-dom";

export default function MainSlider() {
	const slickRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const previous = useCallback(() => slickRef.current.slickPrev(), []);
  const next = useCallback(() => slickRef.current.slickNext(), []);

	const kakao = () => {
		if (window.Kakao) {
			const kakao = window.Kakao;
			if (!kakao.isInitialized()) {
				kakao.init('cc070c6c13d3847a59320562ae6d84bb');
			}
		}
		
		window.Kakao.Channel.chat({
			channelPublicId: '_xnYuNG'
		});
	}

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		beforeChange: (current, next) => {
      setActiveSlide(next);
    },
	};
	return (
		<Wrapper>
			<SlideNumber>
				<span>{activeSlide+1} </span> / 3
			</SlideNumber>
			<Slider {...settings} ref={slickRef}>
				<div>
					<SlideWrap>
						<SlideImg src={slide1} />
						<SlideText>
							<BigText>
								K-Move 해외취업 국비 무료 교육
							</BigText>
							<SmallText>
								수준 높은 IT기술과 최고의 강사들을 통한 일본취업 전문 연수기관<br/>
								최고의 전문가들의 수준 높은 IT기술이 반영된 커리큘럼
							</SmallText>
							<ButtonWrapper>
								<Link to="/track2">
									<MainSliderButton src={mainSlideButton1}/>
								</Link>
								<MainSliderButton src={mainSlideButton2} onClick={kakao}/>
								<MainSliderButton src={mainSlideButton3} />
							</ButtonWrapper>
						</SlideText>
					</SlideWrap>
				</div>
				<div>
					<SlideWrap>
						<SlideImg src={slide2} />
						<SlideText>
							<BigText>
								K-Move 해외취업 국비 무료 교육
							</BigText>
							<SmallText>
								2024년 일본취업 클라우드 기반 풀 스택 웹개발자 양성과정<br/>
								연수생 모집중
							</SmallText>
							<ButtonWrapper>
								<Link to="/track2">
									<MainSliderButton src={mainSlideButton1}/>
								</Link>
								<MainSliderButton src={mainSlideButton2} onClick={kakao}/>
								<MainSliderButton src={mainSlideButton3}/>
							</ButtonWrapper>
						</SlideText>
					</SlideWrap>
				</div>
				<div>
					<SlideWrap>
						<SlideImg src={slide3} />
						<SlideText>
							<BigText>
								국내외 최신 트렌드에 맞춘 IT 핵심기술을 반영
							</BigText>
							<SmallText>
								기업형 글로벌 아카데미 스페이스씨엘<br/>
								맞춤형 교육으로 글로벌 취업의 첫걸음!
							</SmallText>
							<ButtonWrapper>
								<Link to="/track2">
									<MainSliderButton src={mainSlideButton1}/>
								</Link>
								<MainSliderButton src={mainSlideButton2} onClick={kakao}/>
								<MainSliderButton src={mainSlideButton3}/>
							</ButtonWrapper>
						</SlideText>
					</SlideWrap>
				</div>
			</Slider>
			<div>
        <div onClick={previous}>
          <SlideLeftIcon
            src={slideLeftIcon}
            alt={"pre-arrow"}
          />
        </div>
        <div onClick={next}>
          <SlideRightIcon
            src={slideRightIcon}
            alt={"next-arrow"}
          />
        </div>
      </div>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	position: relative;
	width: 100%;
`;

const SlideWrap = styled.div`
	width: 100%;
	height: 671px;
	position: relative;
`;

const SlideText = styled.div`
	width: 800px;
	position: absolute;
	top: 8rem;
	left: 19rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

const BigText = styled.div`
	font-size: 2rem;
	font-family: Pretendard, sans-serif;
	font-weight: 700;
	color: white;
	text-align: left;
`;

const SmallText = styled.div`
	font-size: 1.5rem;
	font-family: Pretendard, sans-serif;
	font-weight: 400;
	color: white;
	text-align: left;
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: 1rem;
`;

const MainSliderButton = styled.img`

`;

const SlideImg = styled.img`
	width: 100%;
	height: 677px;
	object-fit: cover;
`;

const SlideLeftIcon = styled.img`
	width: 40px;
	height: 40px;
	object-fit: cover;
	position: absolute;
	left: 77%;
	top: 82%;
	cursor: pointer;
`;

const SlideRightIcon = styled.img`
	width: 40px;
	height: 40px;
	object-fit: cover;
	position: absolute;
	left: 80%;
	top: 82%;
	cursor: pointer;
`;

const SlideNumber = styled.div`
	width: 72px;
	height: 40px;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	font-size: 16px;
	font-family: Pretendard, sans-serif;
	font-weight: 600;
	text-align: center;
	line-height: 42px;
	border-radius: 20px;
	position: absolute;
	left: 71%;
	top: 82%;
	z-index: 1;

	>span {
		color: rgb(37, 105, 227);
	}
`;
